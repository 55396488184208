import axios from 'axios'
import store from '@/store'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import modal from 'ant-design-vue/es/modal'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // baseURL: process.env.NODE_ENV ==='production'?process.env.VUE_APP_API_BASE_URL:
  //   // 'http://120.79.179.187:10009',
  //    'http://wcg_test.ponylearn.com:8080/api',
  // //   // 'http://wcg.ponylearn.com/api',
  timeout: 60000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    // config.headers[ACCESS_TOKEN] = token
    config.headers["authorization"] = 'Bearer ' + token
    // config.headers["authorization"] = 'Bearer ' + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjc3NDAwNjc1MTUsImlhdCI6MTY5MjY3MjMxNSwidXNlciI6IjEyMzQ0OTkifQ.rCpFNKYmRxsglWO70I8yBgviBTmyjemMwx_Rx85Ar9s"
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  const {code} = response.data
  if (code === 401) {
    modal.error({
      title: '提示',
      content: '不好意思，因您的异常行为，账号已被管理员停用，请联系管理员处理',
      okText: '知道了'
    });
    store.dispatch('Logout').then(() => {
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    })
    return response.data
  } else {
    return response.data
  }
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
