import router, { resetRouter } from './router'
import store from './store'
import storage from 'store'
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import notification from 'ant-design-vue/es/notification'
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { i18nRender } from '@/locales'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const allowList = ['login', 'register', 'registerResult'] // no redirect allowList
const loginRoutePath = '/user/login'
const defaultRoutePath = '/dashboard/workplace'

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${i18nRender(to.meta.title)} - ${domTitle}`)
  /* has token */
  const token = storage.get(ACCESS_TOKEN)
  if (token) {
    if (to.path === loginRoutePath) {
      next({ path: defaultRoutePath })
      NProgress.done()
    } else {
      // check login user.roles is null
      if (store.getters.roles.length === 0) {
        // request login userInfo
        store
          .dispatch('GetInfo')
          .then(res => {
            console.log('res', res)
            // 根据用户权限信息生成可访问的路由表
            store.dispatch('GenerateRoutes', { token, ...res }).then(() => {
              // 动态添加可访问路由表
              // VueRouter@3.5.0+ New API
              resetRouter() // 重置路由 防止退出重新登录或者 token 过期后页面未刷新，导致的路由重复添加
              store.getters.addRouters.forEach(r => {
                router.addRoute(r)
              })
              // 请求带有 redirect 重定向时，登录自动重定向到该地址
              let redirect = ""
              let PermissionPath = []
              const PermissionArr = [
                { key: "foundation", path: "/knowledge_tree/foundation_tree"},
                { key: "business", path: "/knowledge_tree/service_tree-form" },
                { key: "association", path: "/knowledge_tree/correlation_of_knowledge_points" },
                { key: "questionBank", path: "/questionbank" },
                { key: "dimension", path: "/title_annotation" },
                { key: "request", path: "/title_request" },
                { key: "teachingView", path: "/teaching_view" },
                { key: "othersTeachingManage", path: "/others_teaching_manage" },// 第三方教辅录入
                { key: "questionCorrection", path: "/correction_and_testing/question_correction" },// 题目批改
                { key: "questionQualityTesting", path: "/correction_and_testing/question_quality_testing" },// 题目质检
                { key: "questionCorrectionReportError", path: "/correction_and_testing/question_correction_report_error" },// 批改报错
                { key: "questionCorrectionStatistics", path: "/question_correction_statistics" },// 批改统计
                { key: "questionQualityStatistics", path: "/question_quality_statistics" }, // 质检统计
                { key: "teachingEnter", path: "/teaching_assistant/teaching_enter" },
                { key: "teachingInspect", path: "/teaching_assistant/catalog_Check" },
                { key: "titleSlicing", path: "/teaching_assistant/title_slicing" },
                { key: "answerSlicing", path: "/teaching_assistant/answer_slicing" },
                { key: "titileAssociation", path: "/teaching_assistant/answer_slicing" },
                { key: "topicStructuring", path: "/topic_structuring" },
                { key: "accountManage", path: "/account_permission/account_manage" },
                { key: "roleManage", path: "/account_permission/role_manage" },
                // { key: "questionCorrectionOperate", path: "/question_correction/operate" },// 批改题目
              ]
              if (storage.get("permissionList")) {
                const permissionList = storage.get("permissionList")
                for (let item of PermissionArr) {
                  if (permissionList.includes(item.key)) {
                    PermissionPath.push(item.path)
                  }
                }
                for (let item of PermissionArr) {
                  if (permissionList.includes(item.key)) {
                    redirect = item.path
                    break
                  }
                }
              }
                if(PermissionPath.includes(to.path)){
                  redirect = decodeURIComponent(from.query.redirect || to.path)
                }
              console.log('to,path', to.path)
              if (to.path === redirect) {
                // set the replace: true so the navigation will not leave a history record
                next({ ...to, replace: true })
              } else {
                // 跳转到目的路由
                next({ path: redirect })
              }
            })
          })
          .catch(() => {
            notification.error({
              message: '错误',
              description: '请求用户信息失败，请重试'
            })
            // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
            store.dispatch('Logout').then(() => {
              next({ path: loginRoutePath, query: { redirect: to.fullPath } })
            })
          })
      } else {
        next()
      }
    }
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({ path: loginRoutePath, query: { redirect: to.fullPath } })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
