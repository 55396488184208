// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
import { VueAxios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import themePluginConfig from '../config/themePluginConfig'
import iconfontjs from './assets/icons/iconfont.js'
import { Icon, Tree, Pagination, Cascader } from 'ant-design-vue'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import MathJax from './js/MathJax.js' // MathJax.js内容在1.3，位置随意

Vue.prototype.MathJax = MathJax


// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
// import './mock'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
import './permission' // permission control
import './utils/filter' // global filter
import './global.less' // global style
import './assets/icon/iconfont.css';

Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': false, 'fullscreen': false, 'keyboard': false, 'url': 'data-source' }
})
Vue.config.productionTip = false

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
Vue.use(Tree)
Vue.use(Pagination)
Vue.use(Cascader)
// use pro-layout components
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)
const myIcon = Icon.createFromIconfontCN({
  scriptUrl: iconfontjs
})
Vue.component('my-icon', myIcon)

window.umi_plugin_ant_themeVar = themePluginConfig.theme

new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex, Logo message
  created: bootstrap,
  render: h => h(App),
  beforeCreate() {
		Vue.prototype.$bus = this//注册全局事件总线
	}
}).$mount('#app')
