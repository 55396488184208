import request from '@/utils/request'

const userApi = {
  Login: '/auth/login',
  Logout: '/auth/logout',
  ForgePassword: '/auth/forge-password',
  Register: '/auth/register',
  twoStepCode: '/auth/2step-code',
  SendSms: '/account/sms',
  SendSmsErr: '/account/sms_err',
  // get my info
  UserInfo: '/user/info',
  UserMenu: '/user/nav',
  rsaGen: '/auth/v1/rsa_gen', //加密字段
  loginBy: '/auth/v1/login_by_password', //账号密码登录
  loginPhone: '/auth/v1/verfiy_code_vaild', //手机号验证码登录
  existPhone: '/auth/v1/check_account_is_exist', //验证手机号是否存在
  sendCode: '/auth/v1/send_code', //获取手机验证码
  configRole:'/auth/v1/config_role', //获取角色管理配置
  createPwd:'/auth/v1/login_create_pwd', //创建密码
  isVaild:'/auth/v1/is_vaild', //手机号码是否被禁用
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  return request({
    url: userApi.Login,
    method: 'post',
    data: parameter
  })
}

//加密字段
export function rsaGen (parameter) {
  return request({
    url: userApi.rsaGen,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

//账号密码登录
export function loginBy (parameter) {
  return request({
    url: userApi.loginBy,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//手机号验证码登录
export function loginPhone (parameter) {
  return request({
    url: userApi.loginPhone,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//验证手机号是否存在
export function existPhone (parameter) {
  return request({
    url: userApi.existPhone,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//创建密码
export function createPwd (parameter) {
  return request({
    url: userApi.createPwd,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//手机号码是否被禁用
export function isVaild(parameter) {
  return request({
    url: userApi.isVaild,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//获取手机验证码
export function sendCode (parameter) {
  return request({
    url: userApi.sendCode,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//获取用户权限
export function configRole (parameter) {
  return request({
    url: userApi.configRole,
    method: 'get',
    params: parameter
  })
}

export function getSmsCaptcha (parameter) {
  return request({
    url: userApi.SendSms,
    method: 'post',
    data: parameter
  })
}

export function getInfo () {
  return request({
    url: userApi.UserInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getCurrentUserNav () {
  return request({
    url: userApi.UserMenu,
    method: 'get'
  })
}

export function logout () {
  return request({
    url: userApi.Logout,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * get user 2step code open?
 * @param parameter {*}
 */
export function get2step (parameter) {
  return request({
    url: userApi.twoStepCode,
    method: 'post',
    data: parameter
  })
}
